input[type=number].input-number–noSpinners {
    -moz-appearance: textfield;
}

input[type=number].input-number–noSpinners::-webkit-inner-spin-button,
input[type=number].input-number–noSpinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Removes awkward default styles for placeholder on input */
*::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $placeholder;
    font-style: italic;
}
*::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholder;
    font-style: italic;
}
*:-ms-input-placeholder {
    /* IE 10+ */
    color: $placeholder;
    font-style: italic;
}
*:-moz-placeholder {
    /* Firefox 18- */
    color: $placeholder;
    font-style: italic;
}

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
    position: relative;
    vertical-align: top;
    width: 100%;
    border-radius: $global-radius;
    @include border;
    background-color: $white;
    appearance: none;
    @include text-ellipsis;

    &:focus {
        border-color: var(--primary, $primary);
        outline: 0;
    }
    &:disabled {
        @include disabled;
    }
    &.dark {
        background-color: $grey-100;
    }
}
input[type="search"] {
    &::-ms-clear,
    &::-ms-reveal {  display: none; width : 0; height: 0; }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input:matches([type="password"], [type="search"]),
textarea,
select {
    color: $primary-font-color;
}
input[type="submit"] {
    background-color: var(--green, $green);
    border-color: var(--green, $green);
    @extend button.button;

    &:hover,
    &:focus {
        background-color: transparent;
        border-color: var(--green, $green);
        color: var(--green, $green);
    }
}
input {
    &[type="email"],
    &[type="number"],
    &[type="search"],
    &[type="text"],
    &[type="tel"],
    &[type="url"],
    &[type="password"] {
        height: 48px;
        padding: 0 15px;
        border-color: $white;
    }
}
textarea,
select {
    padding: 10px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
    border-color: lighten($white, 8.8%);
    white-space: pre-wrap; /* Fixes line breaks for firefox */
}
textarea {
    overflow-y: auto;
}
select {
    height: 48px;

    &:invalid {
        color: $placeholder;
        font-style: italic;
        option {
            color: $grey-700;
            font-style: normal;
        }
    }
}
fieldset {
  padding: 0;
  margin: 0;
  @include border($color: var(--white, $white));
}
label,
legend {
    display: block;
    margin-bottom: 1rem;
    padding-left: .3rem;
    font-size: 1.25rem;
    @include font(500, normal, ('Roboto-Medium', 'Roboto'));
}
// input {
//   &[type="checkbox"],
//   &[type="radio"] {
//     display: inline;
//   }
// }
// label > .label-body {
//   display: inline-block;
//   margin-left: .5rem;
//   font-weight: normal;
// }

form {
    .valid-input {
        span.tooltip {
            display: none;
            &-invalid {
                display: block;
            }
        }
    }
}
form.invalid-form {
    fieldset.valid-input {
        &:invalid {
            border-color: var(--red, $red);
            &:focus-within {
                border-color: var(--primary, $primary);
                span.tooltip {
                    display: none;
                }
            }
            span.tooltip {
                display: block;
            }
            input:invalid {
                border-color: var(--white, $white);
            }
        }
    }
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="password"],
    textarea,
    select {
        &:invalid {
            border-color: var(--red, $red);

            &:focus {
                border-color: var(--primary, $primary);

                & ~ span.tooltip {
                    display: none;
                }
            }
            & ~ span.tooltip {
                display: block;
            }
        }
    }
}
.input-wrapper,
.select-wrapper {
    input:disabled {
        @include disabled;
    }
}
