/* NOTE: html is set to 62.5% so that all the REM measurements are
   based on 10px sizing. So basically 1.5rem = 15px :) */
html {
    height: 100%;
    /* Likely future */
    font-size: 62.5%;
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba($white, 0);
}
body {
    min-height: 100%;
    height: auto;
    background-color: var(--grey-100, $grey-100);
    color: var(--primary-font-color, $primary-font-color);
    @include font(400, normal, ('Roboto'));
    font-size: 1.5em;
    /* currently ems cause chrome bug misinterpreting rems on body element */
    line-height: 1.6;

    &.modal-openend,
    &.overlay {
        overflow: hidden;
    }
}
body,
html {
    margin: 0;
    padding: 0;
}

main {
    padding: 2% 3% 5%;

    @include mq('desktop-wide') {
        padding: 2% $global-padding 5%;
    }
}

*,
*:after,
*:before {
    box-sizing: border-box;
}
*,
*:focus {
    outline: none;
}
*::-moz-focus-inner {
    border: 0;
}
*::selection {
    color: var(--white, $white);
    background: var(--primary, $primary); /* WebKit/Blink Browsers */
}
*::-moz-selection {
    color: var(--white, $white);
    background: var(--primary, $primary); /* Gecko Browsers */
}
/* Firefox specific rule */
// @supports (-moz-appearance:meterbar) {
//     body {
//         font-weight: lighter !important;
//     }
// }
.text-center {
    text-align: center;
}
