$btn-height: 46px;
$btn-radius: 23px;
$btn-config: 'primary' var(--primary, $primary), 'accept' var(--green, $green), 'warning' var(--orange, $orange), 'danger' var(--red, $red);

button.button,
.button-icon {
    height: $btn-height;
    @include border;
    cursor: pointer;
    text-align: center;
    transition: all 0.5s ease;
    transition-property: border-color, background-color, box-shadow, transform, color;
}

button.disabled {
    @include disabled;
}

button.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem 3.2rem 0.4rem;
    background-color: var(--grey-500, $grey-500);
    border-color: var(--grey-500, $grey-500);
    border-radius: $btn-radius;
    box-sizing: border-box;
    box-shadow: none;
    text-decoration: none;
    white-space: nowrap;
    color: $white;
    letter-spacing: 0.05rem;

    @include mq('tablet') {
        /* FIX VERTICAL TEXT CENTER FOR DESKTOP */
        padding-top: 2px;
    }

    &:active:focus,
    &:focus,
    &.ghost:focus,
    &.ghost:hover {
        background-color: transparent;
        border-color: var(--primary, $primary);
        color: var(--primary, $primary);
    }

    &:hover {
        background-color: transparent;
        border-color: var(--grey-500, $grey-500);
        color: $grey-500;
    }

    &:disabled {
        @include disabled;
    }

    &.active,
    &.ghost.active {
        background-color: var(--primary, $primary);
        border-color: var(--primary, $primary);
        color: $white;

        svg-icon {
            fill: currentColor;
            stroke: currentColor;
        }
    }

    &.ghost {
        color: var(--grey-500, $grey-500);
        background-color: transparent;

        svg-icon {
            width: 14px;
            margin-right: 1rem;
            fill: currentColor;
            stroke: currentColor;
        }

        &--extended {
            background-color: rgba(255, 255, 255, .3);
            border-color: $white;
            color: $white;

            &:focus,
            &:hover {
                color: $white;
                background-color: rgba(255, 255, 255, .3);
                border-color: $white;
            }
        }
    }

    @each $tuple in $btn-config {
        &.#{nth($tuple, 1)} {
            background-color: nth($tuple, 2);
            border-color: nth($tuple, 2);
            color: $white;

            &:hover {
                background-color: transparent;
                color: nth($tuple, 2);
            }
        }
    }

    &.cancel {
        &:hover {
            background-color: transparent;
            border-color: var(--red, $red);
            color: var(--red, $red);
        }
    }

    &.action {
        &:focus {
            background-color: var(--grey-500, $grey-500);
            border-color: var(--grey-500, $grey-500);
            color: var(--white, $white);
            outline: 0;
        }

        &:hover {
            background-color: transparent;
            color: var(--grey-500, $grey-500);

            svg-icon {
                fill: currentColor;
            }
        }

        svg-icon {
            width: 14px;
            fill: $white;
            margin-right: .5rem;
        }
    }

    &.small {
        height: 28px;
        padding: 0 2.2rem 0.18rem;
        font-size: 1.3rem;

        svg-icon {
            width: 9px;
            margin-left: 0.6em;
            fill: currentColor;
        }
    }


    &.smaller {
        padding: 0 1rem 0.18rem;
    }

    &.square {
        padding-right: 13px;
        padding-left: 13px;
        border-radius: 4px;
    }
}

.button-icon {
    width: $btn-height;
    padding: 0;
    background-color: var(--grey-200, $grey-200);
    border-color: transparent;
    border-radius: 50%;
    transform: translateZ(0);
    backface-visibility: hidden;

    svg-icon {
        width: 16px;
        transition: all 0.5s ease;
        transition-property: fill, stroke;


        // @include mq('tablet') {
        //     padding-bottom: 2px;
        // }

        svg {
            vertical-align: middle;
        }

        &.lr-icon svg g{
            stroke-width: 4px;
        }
    }

    &:focus,
    &:hover {
        background-color: var(--primary, $primary);

        svg-icon {
            fill: $white;
            stroke: $white;
        }
    }

    &.add {
        &:hover {
            transform: perspective(1px) scale(1.2);
        }

        &:active {
            transform: scale(.98);
        }
    }

    &.delete {
        background-color: var(--red, $red);
    }

    &.modify {
        background-color: var(--primary, $primary);
    }

    &.delete,
    &.modify {
        svg-icon {
            width: 19px;
            fill: var(--white, $white);
            stroke: var(--white, $white);
        }

        &.disabled {
            background-color: var(--grey-200, $grey-200);
            opacity: .4;
            svg-icon {
                stroke: var(--grey-300, $grey-300);
                fill: var(--grey-300, $grey-300);
            }
        }
    }

    &.small {
        width: 36px;
        height: 36px;
    }

    &.disabled {
        opacity: 0.6;
        pointer-events: all;
    }

    @each $tuple in $btn-config {
        &.#{nth($tuple, 1)} {
            background-color: nth($tuple, 2);
            border-color: nth($tuple, 2);

            svg-icon {
                fill: var(--white, $white);
                stroke: var(--white, $white);
            }

            &.outline {
                svg-icon {
                    fill: nth($tuple, 2);
                    stroke: nth($tuple, 2);
                }


                &.disabled {
                    border-color: var(--grey-300, $grey-300);
                    opacity: 0.6;
                    svg-icon {
                        stroke: var(--grey-300, $grey-300);
                        fill: var(--grey-300, $grey-300);
                    }
                }
            }
        }
    }

    &.outline {
        background-color: transparent;
    }
}

.clean-button {
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
}
