@mixin font($weight, $style, $family) {
    font-weight: $weight;
    font-style: $style;
    font-family: $family, sans-serif;
    /* fix font-weight issue so fonts looks the same in all browsers */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}
/* SET DEFAULT LABEL SPACING */
@mixin label-spacing($spacing: .6em) {
    padding-left: $spacing;
    margin-bottom: 0;
}
/* SET DEFAULT FOR DISABLED ELEMENT */
@mixin disabled($opacity: .2) {
    opacity: $opacity;
    pointer-events: none;
    cursor: not-allowed;
}
/* SET DEFAULT PSEUDO ELEMENT */
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}
/* SET DEFAULT TEXT ELLIPSIS */
@mixin text-ellipsis($wsp: nowrap, $ovfl: hidden, $teovfl: ellipsis) {
    white-space: $wsp;
    overflow: $ovfl;
    text-overflow: $teovfl;
}
/* SET DEFAULT BORDER STYLES */
@mixin border($style: solid, $width: 1px, $color: transparent) {
  border-style: $style;
  border-width: $width;
  border-color: $color;
}
/* SET DEFAULT FLEX */
@mixin flex($display: flex, $direction: row, $wrap: nowrap) {
    display: $display;
    flex-direction: $direction;
    flex-wrap: $wrap;
}
/* SET MEDIA QUERIES */
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
