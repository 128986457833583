@font-face {
    font-family: 'AGaramondPro-Italic';
    font-weight: normal;
    font-style: normal;
    src: url('assets/fonts/Garamond/AGaramondPro-Italic.eot');
    src: url('assets/fonts/Garamond/AGaramondPro-Italic.eot?#iefix') format('embedded-opentype'),    /* IE6-IE8 */
    url('assets/fonts/Garamond/AGaramondPro-Italic.woff') format('woff'),                            /* Modern Browser */
    url('assets/fonts/Garamond/AGaramondPro-Italic.ttf') format('truetype'),                         /* Safari, Android, iOS */
    url('assets/fonts/Garamond/AGaramondPro-Italic.svg#Roboto') format('svg');                       /* older iOS-Device */
}
