a {
    display: flex;
    text-align: center;
    color: color(grey-500);
    text-decoration: none;
    &:hover {
        outline: 0;
        cursor: pointer;
    }
}

hr {
    display: block;
    width: 100%;
    margin-top: 2em;
    margin-bottom: .1em;
    margin-left: auto;
    margin-right: auto;
    border: .05rem solid $grey-200;
}

svg {
    vertical-align: middle;
}

@include mq('tablet') {
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: $alphaanthracite;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $grey-700;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #aaa;
    }
}
