.grid {
    width: 100%;
    margin-bottom: 70px;
    .row {
        @include flex($wrap: wrap);
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid transparent;
        &:last-child {
            border-bottom-color: rgba(37, 99, 138, 0.185);
        }
    }
}
