/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}
.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}
.toast-top-left {
    top: 12px;
    left: 12px;
}
.toast-top-right {
    top: 12px;
    right: 12px;
}
.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}
.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */
.toast-title {
    font-weight: 300;
}
.toast-message {
    font-weight: 300;
    word-wrap: break-word;

    a,
    label {
        color: $white;
    }
    a:hover {
        color: #CCCCCC;
        text-decoration: none;
    }
}
.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: $white;
    text-shadow: 0 1px 0 $white;

    &:hover,
    &:focus {
        color: $black;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.4;
    }
}

/*Additional properties for button version
iOS requires the button element instead of an anchor tag.
If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}
.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;

    .toast {
        position: relative;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 25px 15px 25px 50px;
        width: 550px;
        border-radius: 0;
        background-position: 15px center;
        background-repeat: no-repeat;
        background-size: 24px;
        color: $white;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZyBmaWxsPSIjRkZGIiBzdHJva2U9IiNGRkYiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIHN0cm9rZS13aWR0aD0iMiI+CiAgICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMSIgZmlsbD0ibm9uZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CiAgICA8bGluZSB4MT0iMTIiIHkxPSI3IiB4Mj0iMTIiIHkyPSIxMyIgZmlsbD0ibm9uZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CiAgICA8Y2lyY2xlIGRhdGEtc3Ryb2tlPSJub25lIiBjeD0iMTIiIGN5PSIxNyIgcj0iMSIgc3Ryb2tlPSJub25lIi8+PC9nPgo8L3N2Zz4K')
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTE5Ljc5Miw0LjIwOEwxOS43OTIsNC4yMDhDMTcuNzc1LDIuMTkyLDE1LjAyNSwxLDEyLDFDNS45NSwxLDEsNS45NSwxLDEyYzAsMy4wMjUsMS4xOTIsNS43NzUsMy4yMDgsNy43OTINCglsMCwwbDAsMEM2LjIyNSwyMS44MDgsOC45NzUsMjMsMTIsMjNjNi4wNSwwLDExLTQuOTUsMTEtMTFDMjMsOC45NzUsMjEuODA4LDYuMjI1LDE5Ljc5Miw0LjIwOEwxOS43OTIsNC4yMDh6IE0xMiwyLjgzMw0KCWMyLjIsMCw0LjIxNywwLjczMyw1Ljc3NSwyLjEwOEw0Ljk0MiwxNy43NzVDMy41NjcsMTYuMjE3LDIuODMzLDE0LjIsMi44MzMsMTJDMi44MzMsNi45NTgsNi45NTgsMi44MzMsMTIsMi44MzN6IE0xMiwyMS4xNjcNCgljLTIuMiwwLTQuMjE3LTAuNzMzLTUuNzc1LTIuMTA4TDE5LjA1OCw2LjIyNUMyMC40MzMsNy43ODMsMjEuMTY3LDkuOCwyMS4xNjcsMTJDMjEuMTY3LDE3LjA0MiwxNy4wNDIsMjEuMTY3LDEyLDIxLjE2N3oiLz4NCjwvc3ZnPg0K');
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTksMjBjLTAuMywwLTAuNS0wLjEtMC43LTAuM2wtNy03Yy0wLjQtMC40LTAuNC0xLDAtMS40czEtMC40LDEuNCwwTDksMTcuNkwyMS4zLDUuM2MwLjQtMC40LDEtMC40LDEuNCwwDQoJCXMwLjQsMSwwLDEuNGwtMTMsMTNDOS41LDE5LjksOS4zLDIwLDksMjB6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .toast,
.toast-container.toast-bottom-center .toast {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
.toast-container.toast-top-full-width .toast,
.toast-container.toast-bottom-full-width .toast {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}
.toast {
    background-color: #030303;
    pointer-events: auto;
}
.toast-success {
    background-color: var(--green, $green);
}
.toast-error {
    background-color: var(--red, $red);
}
.toast-info {
    background-color: var(--primary, $primary);
}
.toast-warning {
    background-color: var(--orange, $orange);
}
.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container {
        .toast.div {
            padding: 8px 8px 8px 50px;
            width: 11em;
        }
        .toast-close-button {
            right: -0.2em;
            top: -0.2em;
        }
    }
}
@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container {
        .toast.div {
            padding: 8px 8px 8px 50px;
            width: 18em;
        }
        .toast-close-button {
            right: -0.2em;
            top: -0.2em;
        }
    }
}
@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container {
        .toast.div {
            padding: 15px 15px 15px 50px;
            width: 25em;
        }
    }
}
