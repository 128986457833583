@import "fonts";

h1, h2, h3, h4, h5, h6 {
  display: block;
  margin-bottom: 2rem;
}
h1, h2, h3 {
  @include font(100, normal, ('Roboto-Thin', 'Roboto'))
}
h4, h5, h6 {
  @include font(300, normal, ('Roboto-Light', 'Roboto'));
}

h1 { font-size: 2.8rem; line-height: 1.3;  letter-spacing: 0; @include mq('phablet') { font-size: 5.0rem; } }
h2 { font-size: 2rem; line-height: 1.25; letter-spacing: .1rem; @include mq('phablet') { font-size: 2.27rem; } }
h3 { font-size: 1.6rem; line-height: 1.3;  letter-spacing: 0; @include mq('phablet') { font-size: 1.8rem; } }
h4 { font-size: 1.6rem; line-height: 1.35; letter-spacing: .08rem; @include mq('phablet') { font-size: 1.8rem; } }
h5 { font-size: 1.5rem; line-height: 1.5;  letter-spacing: .05rem; @include mq('phablet') { font-size: 1.5rem; } }
h6 { font-size: 1.5rem; line-height: 1.6;  letter-spacing: 0; @include mq('phablet') { font-size: 1.5rem; } }

p {
  margin-top: 0;
}
